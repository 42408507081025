import { staticEvents } from './static-events'

type Event = {
    id: String,
    month: String,
    day: String,
    title: String,
    type: String,
    content: String,
}

const pipe = (...fns) => value => fns.reduce((x, f) => f(x), value)

const convertBolds = content =>
    content.replace(/\*\*([^\*]+)\*\*/gm, '<strong>$1</strong>')

const convertCarriageReturns = content =>
    content.split('\n\n').join('</p><p>')

const convertHeadings = content =>
    content.replace(/^##\s(.+)$/gm, '<h2>$1</h2>')

const convertItalics = content =>
    content.replace(/\*([^\*]+)\*/gm, '<i>$1</i>')

const convertLinks = content =>
    content.replace(/\[(.+)\]\((.+)\)/gm, '<a href="$2" target="_blank">$1</a>')

const wrapIntoParagraph = content => `<p>${content}</p>`

const convertMarkdownToHtml = pipe(
    convertLinks,
    convertBolds,
    convertItalics,
    convertHeadings,
    convertCarriageReturns,
    wrapIntoParagraph,
)

export const eventById = async (id): Promise<Event> => {
    const event = staticEvents.find(({ id: eventId }) => eventId === id)
    if (!event) return Promise.reject('nop')
    return Promise.resolve({
        ...event,
        content: convertMarkdownToHtml(event.content),
    })
}
