<template>
    <Header></Header>
    <main>
        <router-view></router-view>
    </main>
</template>

<script setup>
    import Header from './Header'
</script>

<style scoped>
    main {
        max-width: var(--page-width);
        margin: 0 auto;
        padding: 0 16px;
    }
</style>
