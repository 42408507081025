<template>
    <article>
        <header>
            <p class="event-date">{{ day }} {{ month }}</p>
            <p class="filler"></p>
        </header>
        <RouterLink class="title" :to="{ name: 'event', params: { id }}">{{ title }}</RouterLink>
        <!-- <QuickActions :id="id" :type="type"></QuickActions> -->
    </article>
</template>

<script setup>
    import { RouterLink } from 'vue-router'
    import QuickActions from './QuickActions'

    defineProps({
        day: String,
        id: String,
        month: String,
        title: String,
        type: String,
    })
</script>

<style scoped>
    header {
        margin-bottom: 1px;
        display: flex;
        flex-direction: row;
        gap: 1px;
    }

    .event-date {
        margin: 0;
        padding: 8px 16px;
        color: var(--bg-inv);
        font-size: 1rem;
        background-color: var(--bg-high);
    }

    .filler {
        margin: 0;
        padding: 0;
        flex-grow: 1;
        background-color: var(--bg-high);
    }

    .title {
        display: block;
        margin: 0;
        padding: 8px 16px;
        color: var(--bg-inv);
        background-color: var(--bg-high);
    }

    .title:hover {
        color: var(--fg-high);
        background-color: var(--bg-med);
    }
</style>
