<template>
    <header>
        <p id="site-title">Mikado</p>
        <nav>
            <ul>
                <RouterLink class="nav-link" :to="{ name: 'home' }">actus</RouterLink>
                <RouterLink class="nav-link" :to="{ name: 'about' }">à propos</RouterLink>
            </ul>
        </nav>
    </header>
</template>

<style scoped>
    header {
        max-width: var(--page-width);
        margin: 32px auto 32px;
        padding: 0 16px;
    }

    #site-title {
        height: 30px;
        margin: 0;
        font-family: Rubik, sans-serif;
        font-size: 40px;
        font-weight: 800;
        line-height: 40px;
        overflow: hidden;
    }

    nav ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .nav-link {
        font-size: 0.8rem;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--fg-high);
    }

    .nav-link:not(:first-child) {
        margin-left: 8px;
    }

    .nav-link:hover {
        color: var(--fg-med);
    }
</style>
