import { createApp } from 'vue'
import { createMemoryHistory, createRouter } from 'vue-router'
import App from './App.vue'
import About from './pages/About.vue'
import Event from './pages/Event.vue'
import Home from './pages/Home.vue'
import * as style from './assets/css/styles.css'
import * as uicons from './assets/uicons/uicons-solid-rounded.css'

const router = createRouter({
    history: createMemoryHistory(),
    routes: [
        { path: '/', name: 'home', component: Home },
        { path: '/evenement/:id', name: 'event', component: Event },
        { path: '/qui-sommes-nous', name: 'about', component: About },
    ],
})

createApp(App)
    .use(router)
    .mount('#app')
