<template>
    <Page title="À propos">
        <h2>L'association</h2>
        <!-- <p>Mikado est une association tournée vers le jeu de société dit moderne. Elle a été créée le xx/xx/xxxx à Rousson, dans le Gard.</p> -->
        <p>Mikado est une association tournée vers le jeu de société dit moderne. Elle est en cours de création à Rousson, dans le Gard.</p>

        <h2>Le jeu de quoi ?</h2>
        <p>Le secteur du jeu de société est en constante évolution depuis les années 1990. Il existe en france (et dans le monde) de nombreuses associations tournées vers ce loisir et certaines ont pris le pli d'organiser régulièrement un festival du jeu dont l'affluence ne cesse de croître (voir la <a href="https://subverti.com/fr/maps/festivals" target="_blank">carte des festivals sur le site Subverti</a>).</p>
        <p>Chaque année c'est :</p>
        <ul>
            <li>plus de 1000 nouveaux jeux créés</li>
            <li>plus de 500 festivals organisés</li>
        </ul>
        <p>Les boutiques de jeux sont nombreuses. Très nombreuses. Qu'elles soient sous la forme de boutique physique (comme <a href="https://www.lacasajeux.fr" target="_blank">la Casa'Jeux</a> à Alès) ou en ligne (<a href="https://www.philibertnet.com/fr" target="_blank">Philibert</a> étant l'un des plus important) il est très facile de trouver des jeux qui nous correspondent. Qu'on soit débutant ou expert, en famille ou entre potes, amoureux de stratégie ou de jeux d'ambiance, préférant les thèmes historiques ou les zombies... il y a de tout pour tout le monde. Et ça c'est chouette !</p>

        <h2>Valeurs</h2>
        <p>L'association est ouverte à toutes et tous. Les enfants doivent cependant être accompagnés et ils sont les bienvenus à partir du moment où suivre une règle de jeu est acquis.</p>
        <p>En quelques mots :</p>
        <ul class="keywords">
            <li>partage</li>
            <li>respect</li>
            <li>bonne humeur</li>
            <li>découverte</li>
        </ul>
    </Page>
</template>

<script setup>
    import Page from './Page'
</script>

<style>
    .keywords {
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .keywords li {
        padding: 8px 16px;
        color: var(--fg-high);
        background-color: var(--bg-med);
        transform: rotate(5deg);
    }

    .keywords li:nth-child(2n) {
        transform: rotate(-4deg);
    }

    .keywords li:nth-child(3n) {
        transform: rotate(3deg);
    }

    .keywords li:nth-child(4n) {
        transform: rotate(-5deg);
    }

    .keywords li:nth-child(4n) {
        transform: rotate(-2deg);
    }
</style>
