<template>
    <ul class="quick-actions">
        <li class="filler"></li>
        <!-- <li v-if="type === 'upcoming event'">s'inscrire</li> -->
        <!-- <li v-if="type === 'discussion'">répondre</li> -->
        <li><RouterLink :to="{ name: 'event', params: { id } }">consulter</RouterLink></li>
    </ul>
</template>

<script setup>
    import { RouterLink } from 'vue-router'

    defineProps({
        id: String,
        type: String,
    })
</script>

<style scoped>
    .quick-actions {
        margin: 1px 0 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: end;
        list-style-type: none;
        color: var(--background);
        font-size: 0.8rem;
        gap: 1px;
    }

    li {
        background-color: var(--bg-high);
    }

    .filler {
        flex-grow: 1;
    }

    li a {
        padding: 0 8px;
        color: var(--background);
    }

    li a:hover {
        color: var(--fg-high);
        background-color: var(--bg-med);
    }
</style>
