<template>
    <h2 v-if="upcoming.length > 0">À ne pas louper</h2>
    <EventCard
        class="card"
        v-for="event in upcoming"
        v-bind="event"
    ></EventCard>
    <!-- <EventsFilters></EventsFilters> -->
    <h2 v-if="past.length > 0">Dernières actualités</h2>
    <EventCard
        class="card"
        v-for="event in past"
        v-bind="event"
    ></EventCard>
</template>

<script setup>
    import Page from './Page'
    import EventCard from '../components/EventCard'
    import EventsFilters from '../components/EventsFilters'
    import { pastEvents, upcomingEvents } from '../apis/home.api'
    import { computed, onMounted, ref } from 'vue'

    const past = ref([])
    const upcoming = ref([])

    onMounted(async () => {
        pastEvents().then(all => past.value = all)
        upcomingEvents().then(all => upcoming.value = all)
    })

    const eventType = computed(({ type }) => {
        return type.toUppercase()
    })
</script>

<style scoped>
    h2 {
        margin: 32px 0 8px;
        font-size: 1rem;
        text-transform: lowercase;
        border-bottom: 1px solid var(--fg-high);
    }

    .card:not(:last-child) {
        margin-bottom: 8px;
    }
</style>
