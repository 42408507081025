<template>
    <article>
        <h1>{{ title }}</h1>
        <p class="subtitle" v-if="subtitle">{{ subtitle }}</p>
        <section class="content">
            <slot></slot>
        </section>
    </article>
</template>

<script setup>
    defineProps({
        subtitle: String,
        title: String,
    })
</script>

<style scoped>
    article {
        margin-bottom: 4rem;
    }

    h1 {
        margin: 0 0 1px;
        padding: 16px;
        color: #515151;
        font-family: Rubik, sans-serif;
        font-weight: normal;
        font-size: 2rem;
        background-color: var(--bg-high);
    }

    .subtitle {
        margin: 0 0 1px;
        padding: 4px 16px;
        color: #515151;
        font-size: 0.8rem;
        background-color: var(--bg-high);
    }

    .content {
        padding: 16px;
        color: #515151;
        border-color: red;
        background-color: var(--bg-high);
    }
</style>
