export const staticEvents = [
    {
        content: `
## Un quoi ?

Alès, par l'intermédiaire de plusieurs associations et acteurs, va organiser son premier festival du jeu de société.

C'est l'occasion parfaite pour **découvrir des jeux** pour tout âge et pour tout public dans un cadre agréable, avec des animateurs entrainés à vous éviter la lecture des livrets de règles.

Il y aura de quoi manger et boire sur place (en plus ça soutient le festival), et **l'entrée est gratuite** ! On vous y attend nombreux !

## Infos pratiques

Il se déroulera du samedi **25 mai** (10h - 22h) au dimanche **26 mai** (10h - 17h) au **Pôle Culturel et Scientifique de Rochebelle**.

- [page Facebook de l'événement](https://www.facebook.com/profile.php?id=61557243922189&mibextid=ZbWKwL)

- [page web du Pôle Scientifique](https://www.ales.fr/sortir-bouger/science-nature/pole-culturel-scientifique-de-rochebelle/)
        `,
        day: '25',
        id: 'e2',
        month: 'mai.',
        title: "Festival du jeu d'Alès",
        type: 'upcoming event',
    },
    {
        content: `
## On y est !

Le site Mikado démarre dans sa plus simple expression, permettant à minima d'organiser les premières sessions de jeux de société et de communiquer sur les activités de l'association.

## C'est tout ?

Oui. C'est tout pour l'instant.

Cependant il est prévu d'ajouter prochainement des fonctionnalités pour rendre l'ensemble plus vivant et surtout interactif.

À bientôt !
        `,
        day: '5',
        id: 'e1',
        month: 'mai',
        title: 'Lancement du site',
        type: 'upcoming event',
    },
]
