<template>
    <p v-if="!event">chargement ...</p>
    <Page v-else :title="event.title" :subtitle="subtitle">
        <p v-html="event.content"></p>
    </Page>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue'
    import { useRoute } from 'vue-router'
    import Page from './Page'
    import { eventById } from '../apis/fake-events.api'

    const event = ref()
    const route = useRoute()

    const subtitle = computed(() => {
        const fullDate = `${event.value.day} ${event.value.month}`
        switch (event.value.type) {
            case 'discussion' :
                return `discusson créée le ${fullDate}`
            case 'upcoming event' :
                return `aura lieu le ${fullDate}`
            case 'event' :
                return `s'est déroulé le ${fullDate}`
            default:
                return ''
        }
    })

    onMounted(() => {
        eventById(route.params.id)
            .then(result => event.value = result)
            // .catch(() => {})
            // .finally(() => {})
    })
</script>
