<template>
    <ul class="events-filters">
        <li class="left-filter"><span class="active">tous</span></li>
        <li>événements</li>
        <li>discussions</li>
    </ul>
</template>

<style scoped>
    .events-filters {
        margin: 8px 0;
        padding: 1px 0;
        display: flex;
        flex-direction: row;
        gap: 8px;
        list-style-type: none;
        border-top: 1px solid var(--fg-high);
        border-bottom: 1px solid var(--fg-high);
    }

    .events-filters .active {
        padding: 0 4px;
        color: var(--background);
        background-color: var(--bg-high);
    }

    .events-filters li:hover {
        padding: 0 4px;
        color: var(--background);
        background-color: var(--bg-high);
    }

    .left-filter {
        flex-grow: 1;
    }
</style>
